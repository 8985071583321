import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages/Home';
import Fee from './pages/Fee';
import Team from './pages/Team';
import GeneralDentistry from './pages/General-Dentistry';
import RestorativeDentistry from './pages/Restorative-Dentestry';
import RootCanalTreatment from './pages/RootCanalTreatment';
import Invisalign from './pages/Invisalign';
import NiTimeAligners from './pages/NiTimeAligners';
import TeethWhitening from './pages/TeethWhitening';
import PorcelainVeneers from './pages/PorcelainVeneers';
import SFCompositeBonding from './pages/SFCompositeBonding';
import DentalImplants from './pages/DentalImplants';
import AllOn4Implants from './pages/AllOn4Implants';
import Technology from './pages/Technology';
import ContactUs from './pages/ContactUs';
import DentalImplantReferral from './pages/DentalImplantReferral';
import CBCTReferral from './pages/CBCTReferral';
import PP from './pages/PrivacyPolicy';
import TermsofUse from './pages/TermsofUse';
import ComplainPolicy from './pages/ComplainPolicy';
import DentalPlans from './pages/DentalPlans';
import PatientStories from './pages/PatientStories';
import OfferPage from './pages/OfferPage';

import ScrollToTop from "./components/universal/scroll-to-top";
import CookieBanner from "./components/universal/cookies-banner";


function App() {
    return (
        <Router>
            <ScrollToTop />
            <CookieBanner />
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/fee" element={<Fee />} />
                <Route path="/team" element={<Team />} />
                <Route path="/general-dentistry" element={<GeneralDentistry />} />
                <Route path="/restorative-dentistry" element={<RestorativeDentistry />} />
                <Route path="/root-canal" element={<RootCanalTreatment />} />
                <Route path="/invisalign" element={<Invisalign />} />
                <Route path="/niTime-aligners" element={<NiTimeAligners />} />
                <Route path="/teeth-whitening" element={<TeethWhitening />} />
                <Route path="/porcelain-veneers" element={<PorcelainVeneers />} />
                <Route path="/smile-fast-composite-bonding" element={<SFCompositeBonding />} />
                <Route path="/dental-implants" element={<DentalImplants />} />
                <Route path="/all-on-4-dental-implants" element={<AllOn4Implants />} />
                <Route path="/our-technology" element={<Technology />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/referral/dental-implant" element={<DentalImplantReferral />} />
                <Route path="/referral/cbct" element={<CBCTReferral />} />
                <Route path="/privacy-policy" element={<PP />} />
                <Route path="/terms-of-use" element={<TermsofUse />} />
                <Route path="/complain-policy" element={<ComplainPolicy />} />
                <Route path="/dental-plans" element={<DentalPlans />} />
                <Route path="/patient-stories" element={<PatientStories />} />
                <Route path="/offer-page" element={<OfferPage />} />
            </Routes>
        </Router>
    );
}

export default App;
