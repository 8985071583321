import React, { useEffect, useRef, useState } from 'react';
import image1 from '../../assets/img/652ee7990fa02b5381ecddcf_preventative-dentistry-image.webp.png';
import image2 from '../../assets/img/652ee79007d8d95c7035c5ee_restorative-dentistry-image.webp.png';
import image3 from '../../assets/img/652ee78517e69191195816ee_emergency-appointment-image-p-500.webp.png';
import image4 from '../../assets/img/64e3bdf9bb5734e1e85f05e0_invisalign-p-500.webp.png';
import image5 from '../../assets/img/652ee7740941ed09c5efc8e1_cosmetic-dentistry-image.webp.png';
import image6 from '../../assets/img/652ee76ddb2d5fa59b3b9966_teeth-whitening-image-p-500.webp.png';
import 'animate.css'; // Ensure Animate.css is imported

const TreatmentOptions = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <section className='container'>
            <div ref={ref} className="row align-items-center p-5">
                <div className="col-lg-6">
                    <img src="https://dr-nadeem-mu.vercel.app/assets/stop-smiling1.png" className={`p-lg-4 img-fluid rounded-4 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}></img>
                </div>
                <div className="col-lg-6">
                    <div className="container d-flex justify-content-start mt-5">
                        <div className="text-start">
                            <h1 className={`fs-40 text-theme ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                “They look great and I feel confident again, I can eat and drink with liberty”<br></br>
                            </h1>
                            <p className={`p-0 fs-20 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Christine was given a partial denture to replace her failing three front teeth by her dentist, which she hated and it was really affecting her social life.
                            <br></br><br></br>
                            She wanted a fixed solution to replace these missing teeth without worrying about her denture falling out whilst eating. We used two implants to replace three front teeth for Christine.
                            <br></br><br></br>
                            Christine was over the moon with her implant treatment and is now able to eat anything she likes without worrying about her loose denture falling out.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


export default TreatmentOptions;
