// src/components/DentalImplantReferralForm.js
import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';

const DentalImplantReferralForm = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [formStatus, setFormStatus] = useState(null);
    const [buttonText, setButtonText] = useState('Send Message');
    const formRef = useRef(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        setMobileNumber(value);
        setError('');
    };

    const validateMobileNumber = () => {
        const ukMobileRegex = /^0\d{10}$/;

        if (!ukMobileRegex.test(mobileNumber)) {
            setError('Please enter a valid 11 digit UK mobile number.');
        }
    };

    // Setting today's date
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (formRef.current) {
            observer.observe(formRef.current);
        }

        return () => {
            if (formRef.current) {
                observer.unobserve(formRef.current);
            }
        };

    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonText('Sending...');

        emailjs.sendForm(
            'service_pmpre2s',
            'template_kwp69ej',
            e.target,
            'nQBJzdhm_0rQ4QbLv'
        ).then((result) => {
            setFormStatus({ type: 'success', message: 'Form submitted successfully!' });
            e.target.reset();
            setButtonText('Send Message');
            setShowModal(true);
        }, (error) => {
            setFormStatus({ type: 'error', message: 'There was an issue submitting the form.' });
            alert("Error during form submission:", error)
            console.error("Error during form submission:", error);
            setButtonText('Send Message')
        });
    };

    return (
        <section className="container" style={{ marginTop: '50px' }}>
            <div className="row" ref={formRef}>
                <div className={`col-md-3 mt-3 ${isVisible ? 'animate__animated animate__fadeInLeft' : ''}`}>
                    <div className="list-group rounded-0">
                        <Link to="/referral/dental-implant" className="list-group-item list-group-item-action p-3" aria-current="true">
                            Dental Implant Referral Form
                        </Link>
                        <Link to="/referral/cbct" className="list-group-item bg-theme text-white list-group-item-action p-3">
                            CBCT Referral Form
                        </Link>
                    </div>
                </div>

                <div className={`col-md-9 ${isVisible ? 'animate__animated animate__fadeInRight' : ''}`}>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="card border-0 rounded-3">
                            <div className="card-body">
                                <h1 className='fw-400'>CBCT Referral Form</h1>
                                <p>Please complete the form below and we will contact the patient as soon as possible:</p>

                                <div className='row'>
                                    <div className='col-md-9'>
                                        <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Referring Dentist Details</h4>
                                        <input type="hidden" name="referral_name" value="CBCT" />
                                        {/** Referring Dentist Details Fields */}
                                        {[
                                            { label: 'Referring Dentist:*', type: 'text', name: 'referring_dentist' },
                                            { label: 'Practice Name:*', type: 'text', name: 'practice_name' },
                                            { label: 'Practice Address 1:*', type: 'text', name: 'practice_address_1' },
                                            { label: 'Practice Address 2:*', type: 'text', name: 'practice_address_2' },
                                            { label: 'Practice Postcode:*', type: 'text', name: 'practice_postcode', className: 'w-50' },
                                            { label: 'Practice Phone Number:*', type: 'text', name: 'practice_phone_number' },
                                            { label: 'Practice Email:*', type: 'email', name: 'practice_email' },
                                        ].map((field, index) => (
                                            <div key={index} className="row g-2 align-items-center mb-2">
                                                <div className="col-sm-3 text-end fs-14">
                                                    <label className="col-form-label">{field.label}</label>
                                                </div>
                                                <div className="col-sm-9">
                                                {index === 5 ? (
                                                        <div>
                                                            <input 
                                                                type={field.type} 
                                                                className={`form-control rounded-0 ${field.className || ''}`} 
                                                                name={field.name} 
                                                                required 
                                                                pattern="\d{11}"
                                                                title="Phone number must be 11 digits"
                                                            />
                                                            {error && <small className="text-danger">{error}</small>}
                                                        </div>
                                                    ) : (
                                                        <input 
                                                            type={field.type} 
                                                            className={`form-control rounded-0 ${field.className || ''}`} 
                                                            name={field.name} 
                                                            required 
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='col-md-3'>
                                        <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Reasons for Scan*</h4>
                                        <div className="form-check mb-3">
                                            <input className="form-check-input" type="checkbox" name="checkbox_1" value="Implants" id="" />
                                            <label className="form-check-label fs-14" > Implants </label>
                                        </div>
                                        <div className="form-check mb-3">
                                            <input className="form-check-input" type="checkbox" name="checkbox_2" value="Ortho" id="" />
                                            <label className="form-check-label fs-14" > Ortho </label>
                                        </div>
                                        <div className="form-check mb-3">
                                            <input className="form-check-input" type="checkbox" name="checkbox_3" value="Impacted Teeth" id="" />
                                            <label className="form-check-label fs-14" > Impacted Teeth </label>
                                        </div>
                                        <div className="form-check mb-3">
                                            <input className="form-check-input" type="checkbox" name="checkbox_4" value="Endodontics" id="" />
                                            <label className="form-check-label fs-14" > Endodontics </label>
                                        </div>
                                        <div className="form-check mb-3">
                                            <input className="form-check-input" type="checkbox" name="checkbox_5" value="Sinus Exam" id="" />
                                            <label className="form-check-label fs-14" > Sinus Exam </label>
                                        </div>

                                    </div>
                                </div>

                                {/** Patient Details and Summary */}
                                <div className="row mt-4">
                                    <div className="col-md-6">
                                        <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Patient Details</h4>
                                        {[
                                            { label: 'Patient Name:*', type: 'text', name: 'patient_name' },
                                            { label: 'Telephone:*', type: 'text', name: 'patient_telephone' },
                                            { label: 'Mobile:*', type: 'text', name: 'patient_mobile' },
                                            { label: 'Email:*', type: 'email', name: 'patient_email' },
                                            { label: 'Date of Birth:*', type: 'date', name: 'patient_dob' },
                                        ].map((field, index) => (
                                            <div key={index} className="row g-2 align-items-center mb-2">
                                                <div className="col-sm-4 text-end fs-14">
                                                    <label className="col-form-label">{field.label}</label>
                                                </div>
                                                <div className="col-sm-8">
                                                {index === 2 ? (
                                                    <div>
                                                        <input 
                                                            type={field.type} 
                                                            className={`form-control rounded-0 ${field.className || ''}`} 
                                                            name={field.name} 
                                                            required 
                                                            pattern="\d{11}"
                                                            title="Phone number must be 11 digits"
                                                        />
                                                        {error && <small className="text-danger">{error}</small>}
                                                    </div>
                                                    ) : (
                                                    <input 
                                                        type={field.type} 
                                                        className={`form-control rounded-0 ${field.className || ''}`} 
                                                        name={field.name} 
                                                        required 
                                                    />
                                                )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="col-md-6">
                                        <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Relevant Medical History*</h4>
                                        <textarea className="form-control rounded-0" rows="9" name="relevant_medical_history" required></textarea>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-8">

                                        <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Areas of Intrest</h4>
                                        <div className="form-check form-check-inline me-5">
                                            <input
                                                className="form-check-input"
                                                type="checkbox" name="intrest_1"
                                                id=""
                                                value="Dual Jaw"
                                            />
                                            <label className="form-check-label fs-14" >Dual Jaw</label>
                                        </div>
                                        <div className="form-check form-check-inline me-5">
                                            <input
                                                className="form-check-input"
                                                type="checkbox" name="intrest_2"
                                                id=""
                                                value="Mandible"
                                            />
                                            <label className="form-check-label fs-14" >Mandible</label>
                                        </div>
                                        <div className="form-check form-check-inline me-5">
                                            <input
                                                className="form-check-input"
                                                type="checkbox" name="intrest_3"
                                                id=""
                                                value="Maxilla"
                                            />
                                            <label className="form-check-label fs-14" >Maxilla</label>
                                        </div>
                                        <div className="form-check form-check-inline me-5">
                                            <input
                                                className="form-check-input"
                                                type="checkbox" name="intrest_4"
                                                id=""
                                                value="Small Field 5x5"
                                            />
                                            <label className="form-check-label fs-14" >Small Field 5x5</label>
                                        </div>
                                        <div className="form-check form-check-inline me-5">
                                            <input
                                                className="form-check-input"
                                                type="checkbox" name="intrest_5"
                                                id=""
                                                value="True 2D OPG"
                                            />
                                            <label className="form-check-label fs-14" >True 2D OPG</label>
                                        </div>

                                        <img src="https://www.mgimplants.co.uk/media/1071/scannermouth1.jpg?anchor=center&mode=crop&width=500&height=130&rnd=131336111020000000" className='imf-fluid w-100 mt-3 mb-3'></img>

                                        <div className="mb-3">
                                            <label className="form-label fs-14">Area of Interest*</label>
                                            <input type="text" name="" id="" className="form-control" placeholder="" aria-describedby="helpId" name="areas_of_intrest" />
                                            <small id="helpId" className="text-muted">(please enter numbers separated by commas)</small>
                                        </div>


                                    </div>

                                    <div className="col-md-4">
                                        <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Reporting</h4>
                                        <p className='fs-14'>To comply with IRMER 2000 regulations radiographs and CT scans should be reviewed by
                                            the referring practitioner or by a radiologist. This rule can rule out the possibility
                                            of coincidental pathology. We recommend this service and are happy to offer a report via
                                            a Consultant Radiologist.</p>
                                        <p className='fs-14 fw-600'>By not ticking this box I am happy to make my own arrangements.</p>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="Yes" id="" name="reporting" />
                                            <label className="form-check-label fs-14" > Reporting (Tick if Yes) </label>
                                        </div>
                                    </div>
                                </div>

                                {/** Special Instructions */}
                                <div className="row mt-4">
                                    <h4 className="border-3 border-bottom py-2 mb-3 fw-400">Special Instructions*</h4>
                                    <textarea className="form-control rounded-0" rows="5" name="special_instructions" required></textarea>
                                </div>

                                {/** Practitioner Name and Date */}
                                <div className="row g-2 align-items-center mb-2 mt-3">
                                    <div className="col-sm-3 text-end fs-14">
                                        <label className="col-form-label">Signature (Type Name):</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control rounded-0 " name="practitioner_name" required />
                                    </div>
                                </div>

                                <div className="row g-2 align-items-center mb-2">
                                    <input type="hidden" name="date" value={formattedDate}></input>
                                    <div className="col-sm-3 text-start fs-14 text-muted">Date {formattedDate}</div>
                                    <div className="col-sm-9">
                                        <input type="checkbox" required />
                                        <label className="col-form-label ms-2">
                                            Please tick this box to agree to our <a href="/privacy-policy" target='_blank' className="text-golden">privacy policy</a> before continuing.
                                        </label>
                                    </div>
                                </div>

                                {/** Submit and Clear Buttons */}
                                <div className="row">
                                    <div className='col-md-3 d-grid'>
                                        <button type="reset" className="btn btn-light me-2 rounded-0 w-100">Clear</button>
                                    </div>
                                    <div className='col-md-9'>
                                        <button type="submit" className="btn btn-theme rounded-0 w-100" id="submitBtn">{buttonText}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Success Modal */}
            {showModal && (
                    <div className="modal d-block border-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content bg-success text-white">
                                <div className="modal-header bg-theme d-flex justify-content-end border-0">
                                    <a type="button" onClick={() => setShowModal(false)}>
                                        <i class="fa fa-times text-white" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div className="modal-body bg-theme p-4 text-center">
                                    <p className='fs-20'><span className='text-golden'>Thank you</span> for getting in touch, one of the team will be in touch soon!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </section >
    );
};

export default DentalImplantReferralForm;
