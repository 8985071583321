import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/img/logo.png';
import 'font-awesome/css/font-awesome.min.css';
import 'animate.css'; // Ensure Animate.css is imported

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div className="row bg-theme" ref={ref}>
            <div className="col-12 container">
                <div className="row px-5 py-3 align-items-center">
                    <div className="col-md-6 p-3">
                        <img 
                            src={Logo} 
                            alt="" 
                            height="60" 
                            className={`animate__animated ${isVisible ? 'animate__bounceIn' : 'animate__bounceOut'}`}
                        />
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end px-5">
                        <p className={`mb-0 text-light animate__animated ${isVisible ? 'animate__bounceIn' : 'animate__bounceOut'}`}>
                            <span className='text-golden fs-20'> Follow Us </span> &nbsp;&nbsp;
                            <i className="fa fa-facebook me-2" aria-hidden="true"></i>&nbsp;&nbsp;
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </p>
                    </div>
                    <div className='col-12 border mt-3'></div>
                    <div className='col-12'>
                        <div className="text-center text-light mt-3">
                            <p className={`mb-0 fs-11 footer-bottom animate__animated ${isVisible ? 'animate__bounceIn' : 'animate__bounceOut'}`}>
                                © 2024 Regent Dental. All Rights Reserved. <br />
                                <a href="/privacy-policy" className='text-white text-decoration-none'> Privacy & Cookie Policy </a> | <a href="/terms-of-use" className='text-white text-decoration-none'> Terms of Use</a> | <a href="https://www.cqc.org.uk/" target='_blank' className='text-white text-decoration-none'>CQC</a> | <a href="https://www.gdc-uk.org/" target='_blank' target='_blank' className='text-white text-decoration-none'>GDC</a> | <a href="/complain-policy" target='_blank' className='text-white text-decoration-none'>Complaints Policy</a><br />
                                designed and maintained by <a className='text-light text-decoration-none' href="https://www.smile-mc.co.uk/" target="_blank"> smile marketing & consultancy </a> <br />
                                website last updated on 20/11/2024 <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
