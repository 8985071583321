import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import '../../assets/css/team.css';
import 'animate.css';
import 'swiper/css/navigation';
import '../../assets/css/testmonials.css'

const Testimonials = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const swiperRef = useRef(null);

    const goNext = () => {
        if (swiperRef.current) swiperRef.current.slideNext();
    };

    const goPrev = () => {
        if (swiperRef.current) swiperRef.current.slidePrev();
    };

    const reviews = [
        {
            text: "Natalia has given me so much confidence with my new teeth and I can’t stop smiling! The amount of compliments I’ve had is unbelievable. I couldn’t recommend Natalia enough; it was such an easy and warming process. I enjoyed every moment of it!",
        },
        {
            text: "Before composite bonding with Natalia, I was very self-conscious as I had a baby tooth that was much smaller than the rest of my teeth. I would never smile in photos as I hated my smile! Since having composite bonding, I have a lot more confidence and I'm so happy with my new smile! I've had so many compliments on my teeth since composite bonding and couldn't be any happier with the result! I would 100% recommend Natalia as she was very patient and understanding with how I wanted my teeth to be, she made me feel at ease and explained everything perfectly.",
        },
        {
            text: "I am so glad that I have taken the decision to have implants. Natalia was very patient and caring. She is a perfectionist and flexible in her patient plan. She took great care to explain every step of the procedure and is happy to repeat. She is reassuring at every step and I am happy with my implants. I don’t know why I took so long to do this and I won’t hesitate in future. I can heartfully recommend Natalia and I have great confidence in her skill.",
        },
        {
            text: "I'm Rebecca Griffiths and I came to the dentist two years ago wanting that perfect smile. From the very beginning, Dr Naiz and Dr Natalia were so helpful, talking me through every step of the journey and always answering my many questions! I started off with Invisalign and then had whitening and composite bonding to finish it all off. I am so happy with my new smile and can't thank them, the dental nurses and the TCOs enough for helping me feel 100x more confident.",
        },
        {
            text: "Recently had my teeth straightened and whitened with Dr Naiz and I feel so confident with my new smile. Would highly recommend :)"
        },
        {
            text: "Great service! Seen Dr Khan, everything was explained to me, absolutely no pain during treatment and professional and skilled nurse - and I need to mention that before I was always scared of dentists 😁 Highly recommend!"
        },
        {
            text: "I went for an Invisalign consultation, covid precautions were well done throughout the practice. The Invisalign consultant was very knowledgeable, and the scanning process was quick, easy and painless. She (the treatment co-ordinator) didn’t pressure me into signing up for anything. Dr Naiz was professional and courteous, he made sure I understood everything properly and again there was no pressure to go ahead. I definitely recommend this practice. "
        },
        {
            text: "I had braces before but my teeth moved from not wearing my retainers. I got Invisalign with Dr Naiz and couldn't be happier, highly recommend!!!"
        },
        {
            text: "I wanted my teeth straightened and whitened as I had braces before but they had started to move back. I have never had so many compliments on my smile before, I am so happy with them and can't stop smiling. Thanks Naiz"
        },
        {
            text: "My teeth moved since I had braces when I was 18. I didn't want to have fixed braces again so decided to go for Invisalign. I'm so glad I found Dr Naiz, he's so friendly and my journey has been great!"
        },
        {
            text: "I wanted straighter teeth and one of my top ones to move down like the others. I didn't feel any pain when wearing the aligners. I am really happy with my straight teeth, I feel more confident and I smile more now. Dr Khan has been great all through my treatment. I would recommend to anyone looking to straighten their teeth."
        },
        {
            text: "My teeth moved after having braces when I was younger and I came to Naiz to get them straightened and the gap closed. He was amazing throughout the whole journey, very helpful, friendly and made me feel at ease. Now I have my perfect smile and I love it. Thank you Naiz and team :)"
        },
        {
            text: "Just started my Invisalign journey with Dr Naiz and it's been brilliant so far. The consultation and Clincheck stages went so smoothly and I'm so glad I chose to do it. Can't wait to start noticing changes :)"
        },
        {
            text: "I was very nervous at the start of my first consultation with Natalia as I was unsure of the treatment she would recommend. Natalia welcomed me with a warm smile & made me feel very relaxed & calm. She explained everything in great detail; listening attentively to my concerns & gave me sound advice as to how we could proceed & what would be the best result."
        },
        {
            text: "On the day of my treatment with all the facts outlined she gave me the confidence that a good result would be achieved. During the treatment both Natalia & her assistant were attentive, caring & very professional checking constantly that I was comfortable. I am delighted with the result which looks very natural. It has made a huge difference & I feel able to smile naturally without being conscious of not showing my teeth."
        },

    ];

    const renderStars = () => (
        <>
            {[...Array(5)].map((_, index) => (
                <i key={index} className="fa fa-star text-warning"></i>
            ))}
        </>
    );

    return (
        <section className="bg-theme">
            <div className="container p-5">
                <div className="text-center text-light">
                    <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                        You are in good company...
                    </p>
                    <h1 className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                        Great Care,<br />Even Better Reviews
                    </h1>
                </div>

                <Swiper
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    slidesPerView={3}
                    spaceBetween={20}
                    loop
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        1024: { slidesPerView: 3 },
                        640: { slidesPerView: 2 },
                        320: { slidesPerView: 1 },
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper mt-5"
                >
                    {reviews.map((review, index) => (
                        <SwiperSlide key={index}>
                            <div className="card rounded-3 bg-light-gold">
                                <div className="card-body p-4 overflow-scroll testimonial-body">
                                    <div className="mb-3">{renderStars()}</div>
                                    <p className="mb-3">{review.text}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Custom Navigation Buttons */}
                <div className="d-flex justify-content-center mt-5">
                    <button onClick={goPrev} className="btn btn-outline-light rounded-circle p-3 me-3 d-flex justify-content-center align-items-center" style={{height: '50px', width: '50px'}}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <button onClick={goNext} className="btn btn-outline-light rounded-circle p-3 d-flex justify-content-center align-items-center" style={{height: '50px', width: '50px'}}>
                        <i className="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
